<template lang="html">
    <section class="event-view-full grid" v-if="dataContent">
        <router-link :to="{
        name: 'page-entry',
        params: {
          typeContent: this.dataContent.type,
          slug: this.dataContent.slug,
        },
      }" class="content-new" title="Enlace al evento">
            <div class="box-date">


                <span class="number">{{ currentDateDay(dataContent.start_date) }}</span>
                <span class="month"> {{currentDateMonth(dataContent.start_date)}}</span>
            </div>
            <img :src="dataContent.image.url" :alt="dataContent.image_footer" class="img-new" />
            <div class="info-box">
                <h2><Markdown v-if="this.dataContent.name_markdown" class="title" :source="this.dataContent.name_markdown" /></h2>
                <!-- <p class="date-text">
          21 febrero 2012 <span class="author"> Autor</span>
        </p> -->
                <p class="box-dates">
                    <template v-for="theme in dataContent.themes" :key="theme.id">
                        <span class="category-tag">{{ theme.name }}</span>
                    </template>
                    <span class="category-location" v-if="dataContent.zone">{{
                        dataContent.zone.name
                        }}</span>
                </p>
                <p class="subtitle">
                    {{ dataContent.header }}
                </p>
            </div>
        </router-link>
    </section>
</template>

<script lang="js">
    import moment from 'moment'
    import Markdown from 'vue3-markdown-it';
    export default {
        name: 'event-view-full',
        props: ['dataContent'],
        mounted() {

        },
        components: {
            Markdown
        },
        data() {
            return {

            }
        },
        methods: {
            currentDateMonth(date) {
                return moment(date).locale('es').format('MMMM')
            },
            currentDateDay(date) {
                return moment(date).locale('es').format('D')
            },
        },
        computed: {
            typeContent() {
                switch (this.dataContent.type) {
                    case 'entry':
                        return 'noticia'
                    case 'opinion':
                        return 'opinion'
                    case 'interview':
                        return 'entrevista'
                    case 'coverage':
                        return 'reportaje'
                    case 'event':
                        return 'evento'
                    case 'media':
                        return 'multimedia'
                    default:
                        return 'noticia'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .content-new {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: 100%;
        /* max-height: 300px; */
        position: relative;
        border-radius: 13px;
        padding-left: 10px;
        margin-bottom: 20px;

        .category {
            position: absolute;
            left: 0px;
            top: 10px;
            z-index: 2;
            background: $orange;
            font-weight: 900;
            color: white;
            font-size: 16px;
            padding: 5px 15px;
            border-radius: 13px;
        }

        .box-date {
            position: absolute;
            top: 12px;
            left: 20px;
            display: flex;
            flex-direction: column;
            background: $red;
            color: white;
            padding: 10px;
            border-radius: 7px;
            align-items: center;
            min-width: 90px;

            span {
                color: white;
                text-align: center;
                font-family: "Frank Ruhl Libre", serif;
                line-height: 0.8em;

                &.number {
                    font-weight: 700;
                    font-size: 65px;
                }

                &.month {
                    font-size: 18px;
                    text-transform: capitalize;
                }
            }
        }

        .img-new {
            width: 100%;
            border-radius: 13px;
            height: auto;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
            height: 350px;
        }
    }

    .info-box {
        width: 100%;
        padding: 20px 0px;
    }

    .other-related .grid-3 {
        .subtitle {
            display: none;
        }
    }

    .events .grid-3 {
        .subtitle {
            display: none;
        }

        .content-new .box-date {
            top: 0;
            left: 10px;
        }
    }

    @media screen and (max-width:845px) {
        .content-new .box-date span.number {
            font-weight: 700;
            font-size: 40px;
        }

        .content-new .img-new {
            height: 235px;
        }

        .content-new {
            padding-left: 0;
        }

        .events .grid-3 .content-new .box-date {
            top: 0;
            left: 0;
        }
    }
</style>
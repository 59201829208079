<template lang="html">
  <section class="view-opinion-small grid" v-if="dataContent">
    <router-link :to="{
        name: 'page-entry',
        params: {
          typeContent: this.dataContent.type,
          slug: this.dataContent.slug,
          typeMedia: this.typeMedia,
        },
      }" class="content-new" title="Enlace a opinión">
      <div class="info-box">
        <img :src="dataContent.image.url" :alt="dataContent.image_footer" class="img-round" />
      <h2><Markdown v-if="this.dataContent.name_markdown" class="title" :source="this.dataContent.name_markdown" /></h2>
        <p class="box-dates">
          <span class="date-text">{{ currentDateTime(dataContent.publish_date) }}</span>
          <span class="author" v-if="dataContent.writer_name">
            {{ dataContent.writer_name }}</span>
        </p>
        <!-- <span class="category-tag">25 Aniversario Cermi</span>
        <span class="category-location">Madrid</span> -->
        <p v-if="dataContent.featured_header">
          {{ dataContent.featured_header }}
        </p>
      </div>
    </router-link>
  </section>
</template>

<script lang="js">
  import moment from 'moment'
  import Markdown from 'vue3-markdown-it';
  export default {
    name: 'view-opinion-small',
    props: ['dataContent'],
    components: {
      Markdown
    },
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
    },
    computed: {
      typeContent() {
        switch (this.dataContent.type) {
          case 'entry':
            return 'noticia'
          case 'opinion':
            return 'opinion'
          case 'interview':
            return 'entrevista'
          case 'coverage':
            return 'reportaje'
          case 'event':
            return 'evento'
          case 'media':
            return 'multimedia'
          default:
            return 'noticia'
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/colours.scss";

  .view-opinion-small {
    margin-bottom: 20px;
    cursor: pointer;

    .content-new {
      text-decoration: none;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      height: 100%;
      /* max-height: 300px; */
      position: relative;
      border-radius: 13px;
      padding-left: 10px;

      .img-round {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center center;
        margin-bottom: 15px;
      }

      .info-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px 0px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width:750px) {
    .view-opinion-small .content-new .info-box {
      padding: 10px 0;
    }
  }
</style>
<template lang="html">
  <section class="container">
    <!-- {{ this.$route.params.typeContent }} -->
    <!-- {{ this.$route.params.typeMedia }} -->

    <div
      @click="goToBack()"
      class="btn-back"
      title="Volver a la página anterior"
    >
      Volver
    </div>
    <section class="page-entry" v-if="entry">
      <!-- {{entry.type}} -->
      <div class="head-content">
        <div class="box-date" v-if="entry.type == 'event'">
          <span class="number">{{ currentDateDay(entry.start_date) }}</span>
          <span class="month"> {{ currentDateMonth(entry.start_date) }}</span>
        </div>
        <div class="content-cont">
          <Markdown
            role="»heading»"
            aria-level="»1″"
            v-if="this.entry.name_markdown && entry.type != 'other'"
            class="title-h1"
            :source="`<h1>${this.entry.name_markdown}</h1>`"
            html="true"
          />
          <div class="head-others" v-if="entry.type == 'other'">
            <div class="cat-div">
              <template v-for="(theme, index) in entry.themes" :key="theme.id">
                <span v-if="index < 1">{{ theme.name }}</span>
              </template>
            </div>
            <Markdown
              v-if="this.entry.name_markdown"
              class="title-h1"
              :source="this.entry.name_markdown"
            />
          </div>
          <div class="container-head-data">
            <div class="info-data">
              <p class="box-dates">
                <span class="date-text" v-if="entry.type == 'event'">{{
                  currentDateTime(entry.start_date)
                }}</span>
                <template v-else>
                  <span class="date-text">{{
                    currentDateTime(entry.publish_date)
                  }}</span>
                </template>
                <span class="author" v-if="entry.writer_name">{{
                  entry.writer_name
                }}</span>
                <span class="author" v-if="entry.writer_description">
                  | {{ entry.writer_description }}
                </span>
                <template v-for="theme in entry.themes" :key="theme.id">
                  <span class="category-tag">{{ theme.name }}</span>
                </template>
                <span class="category-tag" v-if="entry.file_type">{{
                  typeDocuments
                }}</span>
                <span class="category-location" v-if="entry.zone">{{
                  entry.zone.name
                }}</span>
              </p>
            </div>
            <div class="shared-header">
              <rrssShared :dataTitle="entry.name"></rrssShared>
            </div>
          </div>
          <p
            class="subtitle"
            v-if="entry.type == 'entry' || entry.type == 'event'"
          >
            {{ entry.header }}
          </p>
        </div>
      </div>
      <section
        :class="[
          entry.type == 'event'
            ? 'newsletter-detail cont-evento'
            : 'newsletter-detail',
        ]"
      >
        <!-- //HEAD entry OR HEAD EVENTS-->
        <div
          class="container-img -event"
          v-if="
            entry.type == 'entry' ||
            entry.type == 'event' ||
            (entry.type == 'file' && entry.file_type == 0) ||
            (entry.type == 'file' && entry.file_type == 2)
          "
        >
          <img
            class="main-img"
            :src="entry.image.url"
            :alt="entry.image_footer"
            :title="entry.image_footer"
          />
          <span v-if="entry.image_footer">{{ entry.image_footer }}</span>
        </div>

        <!-- //HEAD OPINION -->
        <div class="container-header-featured" v-if="entry.type == 'opinion'">
          <p class="title-dest-section">Opinión</p>
          <img
            class="main-img"
            :src="entry.image.url"
            :alt="entry.image_footer"
            :title="entry.image_footer"
          />
          <div class="container-info">
            <p class="subtitle">
              {{ entry.featured_header }}
            </p>
          </div>
        </div>
        <!-- //HEAD INTERVIEW -->

        <div v-if="entry.type == 'interview'">
          <div class="container-header-featured -interview">
            <p class="title-dest-section">Entrevista a...</p>
            <img
              class="main-img"
              :src="entry.image.url"
              :alt="entry.image_footer"
              :title="entry.image_footer"
            />
            <div class="container-info">
              <p class="name">{{ entry.interviewed }}</p>
              <p class="charge">
                {{ entry.interviewed_job }}
              </p>
            </div>
          </div>
          <p class="subtitle">
            {{ entry.header }}
          </p>
        </div>
        <!-- //HEAD REPORTAGE -->
        <div v-if="entry.type == 'coverage'">
          <div class="container-header-featured -reportage">
            <p class="title-dest-section">Reportaje</p>
            <img
              class="main-img"
              :src="entry.image.url"
              :alt="entry.image_footer"
              :title="entry.image_footer"
            />
            <div class="container-info">
              <p class="subtitle">
                {{ entry.featured_header }}
              </p>
            </div>
          </div>
          <p class="subtitle">
            {{ entry.header }}
          </p>
        </div>
        <!-- //HEAD AUDIOVISUAL VIDEO -->

        <div v-if="entry.type == 'media' && entry.file_type == 1">
          <template v-for="(el, key) in entry.file_url" :key="key">
            <div class="video-preview-image" v-if="entry.file_url">
              <iframe
                :src="verifyIframe(el)"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>

              <!-- <iframe :src="'https://www.youtube.com/embed/'+ youtube_parser(el)"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe> -->
            </div>
          </template>
        </div>

        <template v-if="entry && entry.type != 'other'">
          <EditorJs :object="entry.body" />
        </template>
        <template v-else>
          <div class="content-others">
            <img v-if="entry.image.url" :src="entry.image.url" alt="" />
            <p class="subtitle">{{ entry.header }}</p>
            <p class="featured-text">{{ entry.featured_header }}</p>
            <EditorJs :object="entry.body" />
          </div>
        </template>
        <!-- ENTRYS TYPE FILES -->
        <a
          v-if="entry.type == 'file'"
          download=""
          :href="entry.file[0].url"
          class="url-download"
          rel="noopener"
          title="Enlace de descarga"
          target="_blank"
          >Descargar archivo</a
        >
        <p>
          <strong v-if="entry.interviewer">Por: </strong
          ><span>{{ entry.interviewer }}</span>
        </p>
        <p v-if="entry.source" class="source-p">
          <strong>Fuente: </strong><span>{{ entry.source }}</span>
        </p>

        <div class="w-100 suscription" v-if="entry.join_url">
          <a
            target="_blank"
            class="btn-blue"
            :href="entry.join_url"
            title="Inscribirme al evento"
            >Inscribirme</a
          >
        </div>
      </section>
    </section>
    <section
      class="other-related"
      v-if="this.$route.params.entryType == 'entry'"
    >
      <p class="title-dest-underline">Otras noticias de interés</p>
      <section class="grid-3">
        <newVerticalImg entryType="entry"></newVerticalImg>
        <newVerticalImg entryType="entry"></newVerticalImg>
        <newVerticalImg entryType="entry"></newVerticalImg>
      </section>
    </section>
    <section
      class="other-related"
      v-if="this.$route.params.entryType == 'reportage'"
    >
      <p class="title-dest-underline">Otros Reportajes</p>
      <section class="grid-3">
        <newVerticalImg entryType="reportage"></newVerticalImg>
        <newVerticalImg entryType="reportage"></newVerticalImg>
        <newVerticalImg entryType="reportage"></newVerticalImg>
      </section>
    </section>
    <section
      class="other-related"
      v-if="this.$route.params.entryType == 'interview'"
    >
      <p class="title-dest-underline">Otras Entrevistas</p>
      <section class="grid-3">
        <newVerticalImg entryType="interview"></newVerticalImg>
        <newVerticalImg entryType="interview"></newVerticalImg>
        <newVerticalImg entryType="interview"></newVerticalImg>
      </section>
    </section>
    <section
      class="other-related"
      v-if="this.$route.params.entryType == 'opinion'"
    >
      <p class="title-dest-underline">Otras opiniones de interés</p>
      <section class="grid-3">
        <opinionSmall entryType="opinion"></opinionSmall>
        <opinionSmall entryType="opinion"></opinionSmall>
        <opinionSmall entryType="opinion"></opinionSmall>
      </section>
    </section>
    <section
      class="other-related"
      v-if="this.$route.params.entryType == 'audiovisuals'"
    >
      <p class="title-dest-underline">Otras audiovisuales de interés</p>
      <section class="grid-3">
        <videoSmall entryType="audiovisuals" typeMedia="video"></videoSmall>
        <videoSmall entryType="audiovisuals" typeMedia="video"></videoSmall>
        <videoSmall entryType="audiovisuals" typeMedia="image"></videoSmall>
      </section>
    </section>
    <section
      class="other-related"
      v-if="this.$route.params.entryType == 'event'"
    >
      <p class="title-dest-underline">Otras eventos</p>
      <section class="grid-3">
        <eventViewFull entryType="event"></eventViewFull>
        <eventViewFull entryType="event"></eventViewFull>
        <eventViewFull entryType="event"></eventViewFull>
      </section>
    </section>
  </section>
</template>

<script lang="js">
import {
    mapGetters,
    mapActions
} from 'vuex';
import Markdown from 'vue3-markdown-it';
import EditorJs from "@/components/editorJs.vue";
import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
import opinionSmall from "@/components/opinions/view-opinion-small.vue";
import videoSmall from "@/components/videos/video-preview-small.vue";
import eventViewFull from "@/components/events/event-view-full.vue";
import rrssShared from "@/components/rrss-shared.vue";
import store from '@/store';
import moment from 'moment'
export default {
    name: 'page-entry',
    props: ['slug', 'entryType'],
    components: {
        EditorJs,
        newVerticalImg,
        rrssShared,
        opinionSmall,
        videoSmall,
        eventViewFull,
        Markdown
    },
    mounted() {
        switch (this.$route.params.typeContent) {
            case 'noticia':
                this.entry = store.getters['contents/getNew'](this.slug)
                break;
            case 'opinion':
                this.entry = store.getters['contents/getOpinion'](this.slug)
                break;
            case 'interview':
                this.entry = store.getters['contents/getInterview'](this.slug)
                break;
            case 'coverage':
                this.entry = store.getters['contents/getCoverage'](this.slug)
                break;
            case 'event':
                this.entry = store.getters['contents/getEvent'](this.slug)
                break;
            case 'file':
                this.entry = store.getters['contents/getFile'](this.slug)
                break;
            case 'media':
                this.entry = store.getters['contents/getMedia'](this.slug)
                break;
            case 'other':
                this.entry = store.getters['contents/getOther'](this.slug)
                break;
            default:
                this.entry = store.getters['contents/getNew'](this.slug)
        }

        if (this.entry == null) {
            let callEntry = ''
            switch (this.$route.params.typeContent) {
                case 'noticia':
                    callEntry = this.loadNew({
                        slug: this.slug
                    });
                    break;
                case 'opinion':
                    callEntry = this.loadOpinion({
                        slug: this.slug
                    });
                    break;

                case 'interview':
                    callEntry = this.loadInterview({
                        slug: this.slug
                    });
                    break;
                case 'coverage':
                    callEntry = this.loadCoverage({
                        slug: this.slug
                    });
                    break;
                case 'event':
                    callEntry = this.loadEvent({
                        slug: this.slug
                    });
                    break;
                case 'file':
                    callEntry = this.loadFile({
                        slug: this.slug
                    });
                    break;
                case 'media':
                    callEntry = this.loadMedia({
                        slug: this.slug
                    });
                    break;
                case 'other':
                    callEntry = this.loadOther({
                        slug: this.slug
                    });
                    break;

                default:
                    callEntry = this.loadNew({
                        slug: this.slug
                    });
            }
            let m = this
            //Resolvemos el promise con un then
            callEntry.then(function (result) {
                m.entry = result
                m.changeMetaUrl()
            });
        }
    },
    data() {
        return {
            entry: null,
        }
    },
    computed: {
        typeDocuments() {
            switch (this.entry.file_type) {
                case 1:
                    return 'Informe'
                case 2:
                    return 'Manifiesto'
                case 3:
                    return 'Revista'
                default:
                    return 'Documento'
            }

        },
    },
    methods: {
        ...mapActions({
            loadNew: 'contents/loadNew',
            loadOpinion: 'contents/loadOpinion',
            loadInterview: 'contents/loadInterview',
            loadCoverage: 'contents/loadCoverage',
            loadEvent: 'contents/loadEvent',
            loadFile: 'contents/loadFile',
            loadMedia: 'contents/loadMedia',
            loadOther: 'contents/loadOther'
        }),
        currentDateTime(date) {
            return moment(date).locale('es').format('L')
        },
        currentDateMonth(date) {
            return moment(date).locale('es').format('MMMM')
        },
        currentDateDay(date) {
            return moment(date).locale('es').format('D')
        },
        verifyIframe(url) {
            var video_id = '';
            var ampersandPosition = '';
            if (url.indexOf('vimeo.com/') > 0) {
                video_id = url.split('vimeo.com/')[1];
                ampersandPosition = video_id.indexOf('&');
                video_id = 'https://player.vimeo.com/video/' + video_id;

                return video_id
            }
            else if (url.indexOf('v=') > 0) {
                video_id = url.split('v=')[1];
                ampersandPosition = video_id.indexOf('&');
                if (ampersandPosition != -1) {
                    video_id = video_id.substring(0, ampersandPosition);
                }
                return 'https://www.youtube.com/embed/' + video_id
            }

            else {
                return
            }

        },
        changeMetaUrl: function () {
            let title = this.entry.seo_name ? this.entry.seo_name : this.entry.name
            let description = this.entry.seo_description ? this.entry.seo_description : this.entry.header
            document.title = title
            document.head.querySelector("[name=description]").content = description
            document.head.querySelector("[name=title]").content = title
            let imgPng = null
            if (this.entry.image.url) {
                let posicion = this.entry.image.url.indexOf('/catalog/');
                let inicio = this.entry.image.url.substring(0, posicion);
                let subcadena = this.entry.image.url.substring(posicion, this.entry.image.url.length);
                subcadena = '/convert' + subcadena.replace('.webp', '.png')
              // imgPng = inicio + subcadena
                 imgPng = this.entry.image.url
                document.head.querySelector("[property='og:image']").content = imgPng;
            }
            //
            document.head.querySelector("[property='og:url']").content = window.location.href;
            document.head.querySelector("[property='og:title']").content = title;
            if (this.entry.image.url) {
                document.head.querySelector("[property='og:image']").content = imgPng;
            }
            document.head.querySelector("[property='og:description']").content = description
            //
            document.head.querySelector("[name='twitter:title']").content = title;
            document.head.querySelector("[name='twitter:description']").content = description;
            if (this.entry.image.url) {
                document.head.querySelector("[name='twitter:image']").content = imgPng;
            }
            document.head.querySelector("[name='twitter:text:title").content = title;
        },
        goToBack() {
            this.$router.back()
        }
    },
    watch: {
        'entry'() {
            this.changeMetaUrl()
        }
    }

}
</script>

<style scoped lang="scss">
@import "@/styles/colours.scss";

.page-entry {
  margin-top: 25px;

  .title-h1 {
    margin-bottom: 10px;
  }

  .category-location {
    margin-left: 15px;
  }

  .container-img {
    padding: 20px 40px;

    &.-event {
      padding-left: 105px;
    }

    span {
      text-align: center;
      width: 100%;
      display: inline-block;
    }

    .main-img {
      width: 100%;
      height: 400px;
      object-fit: contain;
      border-radius: 13px;
      object-position: center center;
      margin-bottom: 10px;
    }
  }

  .container-header-featured {
    background: $blueLight;
    padding: 40px;
    border-radius: 13px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    margin: 30px 0 20px 0;

    .title-dest-section {
      position: absolute;
      font-size: 135px;
      color: $blueLight;
      font-family: "Frank Ruhl Libre", serif;
      font-weight: 700;
      right: 40px;
      top: -120px;
      margin: 20px 0;
    }

    .main-img {
      width: 250px;
      height: 250px;
      object-fit: cover;
      border-radius: 50%;
      object-position: center center;
      margin-bottom: 10px;
    }

    .container-info {
      width: calc(100% - 250px);
      padding-left: 40px;
      background-image: url(/img/icons/icon-cedilla.svg);
      background-repeat: no-repeat;
      background-position: 40px top;
      background-size: 65px;
      padding-top: 65px;
    }

    &.-interview {
      padding: 0;
      margin-top: 30px;

      .main-img {
        width: 35%;
        height: 250px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 13px;
        -o-object-position: center center;
        object-position: center center;
        margin-bottom: 0px;
      }

      .container-info {
        width: 65%;
        padding-left: 30px;
        padding-right: 30px;
        background: none;

        .name {
          font-size: 30px;
          font-weight: 700;
          line-height: 1.2em;
          color: $blue;
          font-family: "Frank Ruhl Libre", serif;
          margin-bottom: 15px;
        }

        .charge {
          font-size: 24px;
          font-weight: 700;
          line-height: 1.2em;
          color: $blue;
          font-family: "Frank Ruhl Libre", serif;
        }
      }
    }

    &.-reportage {
      padding: 0;
      background: $blue;
      margin-top: 30px;

      .title-dest-section {
        top: -160px;
      }

      .main-img {
        width: 50%;
        height: 350px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
        margin-bottom: 0;
        border-radius: 13px;
      }

      .container-info {
        width: 50%;
        padding: 30px;
        background: none;

        .subtitle {
          color: white;
        }
      }
    }
  }

  .video-preview-image {
    width: 100%;
    max-width: 800px;
    height: 500px;
    margin: 0 auto;
    padding: 40px 0;

    iframe {
      width: 100%;
      max-width: 800px;
      height: 100%;
      border-radius: 13px;
    }
  }

  .suscription {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}

.other-related {
  margin-top: 40px;
}

.url-download {
  background: rgba(0, 105, 180, 0.08);
  text-decoration: none;
  width: 100%;
  display: inline-block;
  padding: 20px 20px 20px 46px;
  cursor: pointer;
  background-image: url(/img/icons/file_download.svg);
  background-repeat: no-repeat;
  background-position: 10px 12px;
  background-size: 30px;
  margin: 40px 0;
}

.head-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .content-cont {
    width: calc(100% - 105px);
    flex-grow: 1;
  }

  .box-date {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    background: $red;
    color: white;
    padding: 10px;
    border-radius: 7px;
    align-items: center;
    width: 90px;
    min-width: 90px;

    span {
      color: white;
      text-align: center;
      font-family: "Frank Ruhl Libre", serif;
      line-height: 0.8em;

      &.number {
        font-weight: 700;
        font-size: 65px;
      }

      &.month {
        font-size: 18px;
      }
    }
  }
}

.container-head-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  .shared-header {
    width: 400px;
    z-index: 2;
    position: relative;

    .rrss-shared {
      margin-top: 0;
    }
  }

  .info-data {
    width: calc(100% - 400px);
  }
}

p,
p b {
  font-size: 17px;
}

.subtitle {
  font-size: 20px;
}

.newsletter-detail.cont-evento {
  padding-bottom: 50px;

  .newsletter-body,
  .source-p {
    padding-left: 107px;
    padding-right: 25px;
  }
}

.author {
  margin-right: 5px;
}

@media screen and (max-width: 845px) {
  .container-head-data .info-data {
    width: 100%;
  }

  .container-head-data .shared-header {
    width: 100%;
  }

  .container-head-data .shared-header .rrss-shared {
    margin-top: 0;
    padding-left: 0 !important;
    margin-bottom: 20px;
  }

  .page-entry .container-img.-event {
    padding: 20px 0;
  }

  .page-entry .container-img .main-img {
    height: auto;
  }

  .page-entry .container-header-featured .title-dest-section {
    font-size: 80px;
    top: -97px;
  }

  .page-entry .container-header-featured.-reportage .main-img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .page-entry .container-header-featured.-reportage .container-info {
    width: 100%;
    padding: 20px;
  }

  .newsletter-detail.cont-evento {
    .newsletter-body,
    .source-p {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: 750px) {
  .page-entry .container-header-featured .title-dest-section {
    font-size: 55px;
    top: -74px;
    left: 0;
  }

  .page-entry .container-header-featured.-reportage .title-dest-section {
    top: -74px;
  }

  .head-content .content-cont {
    width: 100%;
    margin-top: 10px;
  }

  .head-content .box-date {
    margin-right: 0;
  }

  .head-content .box-date span.number {
    font-weight: 700;
    font-size: 40px;
  }

  .page-entry .container-header-featured .container-info {
    width: 100%;
    padding-left: 0;
    background-position: 0 top;
    background-size: 50px;
    padding-top: 48px;
  }

  .page-entry .container-header-featured .main-img {
    width: 100%;
    height: 250px;
    max-width: 250px;
    margin: 0 auto;
  }

  .page-entry .container-header-featured.-interview .main-img {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
  }

  .page-entry .container-header-featured.-interview .container-info {
    width: 100%;
    padding: 20px;
  }

  .page-entry .container-header-featured.-interview .container-info .name {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .page-entry .container-header-featured {
    padding: 20px;
  }
}
</style>

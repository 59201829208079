<template lang="html">
  <section class="video-preview-small grid">
    <div
      @click="gotoPageEntry()"
      class="content-new"
      title="Enlace a la noticia"
    >
      <div class="content-image">
        <span
          src="/img/img-test.png"
         :alt="dataContent.image_footer"
          :class="typeMedia + ' mask-media'"
        ></span>
        <img
          src="/img/img-test.png"
         :alt="dataContent.image_footer"
          class="img-new"
        />
      </div>
      <div class="info-box">
        <p class="title">
          Generar alianzas naturales facilita la innovación social en el mundo
          rural entre personas con discapacidad
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="js">
    export default {
        name: 'video-preview-small',
        props: ['entryType',
            'typeMedia'
        ],
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            gotoPageEntry(event) {
                this.$router.push({
                    name: 'page-entry',
                    params: {
                        slug: 'test',
                        entryType: this.entryType,
                        typeMedia: this.typeMedia,

                    }
                })
            }
        },
        computed: {

        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";
    .content-new {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: 100%;
        /* max-height: 300px; */
        position: relative;
        border-radius: 13px;
        padding-left: 10px;
        .content-image {
            position: relative;
            .mask-media {
                background-color: rgba(0, 0, 0, 0.2);
                width: 100%;
                height: 220px;
                top: 0;
                left: 0;
                position: absolute;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 45px;
                border-radius: 13px;
                &.image {
                    background-image: url("/img/icons/icon-photo.svg");
                    background-size: 30px;
                }
                &.video {
                    background-image: url("/img/icons/icon-video.svg");
                }
            }
            .img-new {
                width: 100%;
                border-radius: 13px;
                height: auto;
                -o-object-fit: cover;
                object-fit: cover;
                -o-object-position: center;
                object-position: center;
                height: 220px;
            }
        }
    }
    
    .info-box {
        width: 100%;
        padding: 20px 0px;
        .category-location {
            margin-left: 15px;
        }
        .title {
            font-size: 22px;
            color: $blue;
        }
    }
</style>
<template lang="html">
  <section class="rrss-shared container">
    <div class="rrss" data-v-29e8c3c6="">
      <span title="Compartir en redes sociales" alt="Compartir en redes sociales" data-v-29e8c3c6="">Compartir en redes
        sociales:</span>
      <a :href="
          'http://www.facebook.com/sharer.php?u=' + url + '&t=' + dataTitle
        " title="Compartir en Facebook" alt="Compartir en Facebook" target="_blank" data-v-29e8c3c6=""><i
          class="fa fa-facebook" aria-hidden="true" data-v-29e8c3c6=""></i></a>
      <a :href="
                  'http://twitter.com/intent/tweet?text=' +
                    dataTitle +
                    '&url=' +
                    url
                " title="Compartir en X" alt="Compartir en X" target="_blank" data-v-29e8c3c6=""><i
          class="fa-brands fa-x-twitter" aria-hidden="true" data-v-29e8c3c6=""></i></a>
      <a :href="'https://www.linkedin.com/sharing/share-offsite/?url='+url" title="Compartir en Linkedin"
        alt="Compartir en Linkedin" target="_blank" data-v-29e8c3c6=""><i class="fa fa-linkedin" aria-hidden="true"
          data-v-29e8c3c6=""></i></a>
      <a :href="'https://telegram.me/share/url?url='+url+ '&text=' + dataTitle" title="Compartir en Telegram"
        alt="Compartir en Telegram" target="_blank" data-v-29e8c3c6=""><i class="fa fa-telegram" aria-hidden="true"
          data-v-29e8c3c6=""></i></a>
    </div>
  </section>
</template>

<script lang="js">
  export default {
    name: 'rrss-shared',
    props: ['dataTitle'],
    mounted() {

    },
    data() {
      return {
        url: location.href,
      }
    },
    methods: {

    },
    computed: {

    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/colours.scss";

  .rrss-shared {
    margin-top: 40px;

    .rrss {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;

      span {
        color: $blue;
        font-weight: 700;
      }

      a {
        background: $blue;
        border-radius: 50%;
        margin-left: 10px;
        width: 25px;
        height: 25px;
        text-align: center;
        display: flex;
        justify-content: center;
        text-decoration: none;
        align-items: center;
        border: 1px solid $blue;
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
        transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

        &:hover {
          border: 1px solid $blue;
          background: white;
          transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);

          .fa, .fa-brands {
            color: $blue;
            transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
          }
        }

        .fa ,.fa, .fa-brands{
          color: white;
          font-size: 15px;
        }
      }
    }
  }

  @media screen and (max-width:845px) {
    .rrss-shared .rrss {
      justify-content: flex-start;
    }
  }
</style>